<template>
    <van-popup
        class="tree_popup"
        v-model="showTreePop"
        closeable
        close-icon="arrow-left"
        close-icon-position="top-left"
        position="right"
        :style="{ width: '100%', height: '100%' }"
    >
        <van-sticky class="fixed_div">
            <div class="tree_title">
                <span>{{ `请选择${treeTitle}` }}</span>
            </div>
            <div class="tree_search">
                <van-search
                    v-model="searchTreeVal"
                    placeholder="请输入搜索关键词"
                    @search="onSearchTree"
                />
            </div>
            <div class="tree_head">
                <span class="head_start" @click="backTo(true)">开始</span>
                <span
                    v-for="(ele, idex) in clickParentTree"
                    :key="idex"
                    :class="idex == clickParentTree.length - 1 ? 'theLast' : 'noLast'"
                    @click="backTo(ele)"
                >
                    <span class="division">></span>
                    {{ ele.keyname }}
                </span>
            </div>
            <van-divider
                :style="{
                    color: '#E8E8E8',
                    borderColor: '#E8E8E8',
                    margin: '5px 0 0 0',
                }"
            />
        </van-sticky>
        <div class="tree_body">
            <!-- 岗位树和员工树 -->
            <div v-if="whichTree == 'station' || whichTree == 'employee'">
                <!-- 多选树 -->
                <div v-if="multiseltree">
                    <!-- 父节点 -->
                    <van-cell
                        class="parents_cell"
                        v-for="(item, index) in parentTreeList"
                        :key="index + item.keyname"
                        :title="item.keyname"
                    >
                        <template #default>
                            <div
                                :class="item.hasLower ? 'haveClo' : 'noClo'"
                                @click="checkParent(item)"
                            >
                                <van-icon name="arrow-down" />
                                <span>下级</span>
                            </div>
                        </template>
                        <template #icon>
                            <van-checkbox
                                v-model="item.checked"
                                @click="tickParent(item)"
                                shape="square"
                            ></van-checkbox>
                        </template>
                    </van-cell>
                    <!-- 子节点 -->
                    <van-cell
                        class="childs_cell"
                        v-for="(it, idx) in childsTreeList"
                        :key="idx"
                    >
                        <template #title>
                            <van-checkbox v-model="it.checked" shape="square">
                                <van-button
                                    size="small"
                                    :icon="whichTree == 'employee' ? 'user-o' : 'manager-o'"
                                    :type="whichTree == 'employee' ? 'warning' : 'primary'"
                                ></van-button>
                                <span class="check_name">{{ it.keyname }}</span>
                            </van-checkbox>
                        </template>
                    </van-cell>
                </div>
                <!-- 非多选 -->
                <div v-else>
                    <!-- 父节点 -->
                    <van-cell
                        class="parents_cell"
                        v-for="(item, index) in parentTreeList"
                        :key="index + item.keyname"
                        :title="item.keyname"
                        @click="checkParent(item)"
                    >
                        <template #default>
                            <div :class="item.hasLower ? 'haveClo' : 'noClo'">
                                <van-icon name="arrow-down" />
                                <span>下级</span>
                            </div>
                        </template>
                    </van-cell>
                    <!-- 子节点 -->
                    <van-cell
                        class="childs_cell"
                        v-for="(it, idx) in childsTreeList"
                        :key="idx"
                        @click="checkChild(it)"
                    >
                        <template #title>
                            <van-button
                                size="small"
                                :icon="whichTree == 'employee' ? 'user-o' : 'manager-o'"
                                :type="whichTree == 'employee' ? 'warning' : 'primary'"
                            ></van-button>
                            <span class="check_name">{{ it.keyname }}</span>
                        </template>
                    </van-cell>
                </div>
            </div>
            <!-- 其他树 -->
            <div v-else>
                <!-- 多选树 -->
                <div v-if="multiseltree">
                    <van-cell
                        class="parents_cell other_cell"
                        center
                        v-for="(item, index) in otherTreeList"
                        :key="index + item.keyname"
                    >
                        <template #icon>
                            <van-checkbox
                                v-model="item.checked"
                                @click="tickParent(item)"
                                shape="square"
                            ></van-checkbox>
                        </template>
                        <template #title>
                            <van-button
                                size="small"
                                icon="cluster-o"
                                color="#1491f7"
                            ></van-button>
                            <span class="check_name">{{ item.keyname }}</span>
                        </template>
                        <template #default>
                            <div
                                v-if="item.hasLower"
                                class="haveClo"
                                @click.stop="hasLower(item)"
                            >
                            <van-icon name="arrow-down" />
                                <span>下级</span>
                            </div>
                        </template>
                    </van-cell>
                </div>
                <!-- 非多选树 -->
                <div v-else>
                    <van-cell
                        class="parents_cell other_cell"
                        center
                        v-for="(item, index) in otherTreeList"
                        :key="index + item.keyname"
                        @click="checkChild(item)"
                    >
                        <template #title>
                            <van-button
                                size="small"
                                icon="cluster-o"
                                color="#1491f7"
                            ></van-button>
                            <span class="check_name">{{ item.keyname }}</span>
                        </template>
                        <template #default>
                            <div
                                v-if="item.hasLower"
                                class="haveClo"
                                @click.stop="hasLower(item)"
                            >
                                <van-icon name="arrow-down" />
                                <span>下级</span>
                            </div>
                        </template>
                    </van-cell>
                </div>
            </div>
        </div>
        <div class="tree_foot" v-if="multiseltree">
            <van-button type="primary" size="small" @click="cleanChecked"
            >清除</van-button
            >
            <van-button
                type="info"
                size="small"
                :disabled="!canComfirm"
                @click="comfirmChecked"
            >确定</van-button
            >
        </div>
    </van-popup>
</template>
<script>
import {
    getCommTreeData
} from "@api/wxjx.js";
import {
    getSelTreeLevel
} from "@api/wx.js";
export default {
    name: 'PopTree',
    props: {
        // 树的类型
        whichTree: {
            type: String,
            default: 'calitemtree'
        },
        // true代表多选
        multiseltree: {
            type: Boolean,
            default: false
        },
    },
    data() {
        const userInfo = localStorage.userInfo
            ? JSON.parse(localStorage.userInfo)
            : {};
        return {
            userInfo,
            showTreePop:false,        // 显示树
            searchTreeVal: "", // 树弹窗搜索词
            allTreeList: [], // 所有树集合
            parentTreeList: [], // 上级树集合（针对岗位树和员工数）
            childsTreeList: [], // 下级树集合（针对岗位树和员工数）
            clickParentTree: [], // 储存点击过的父节点
            otherTreeList: [], // 其他类型树集合
            canComfirm: false, // 多选树确定按钮是否可以点击
            toWitchCode: "", // 赋值哪个工号
            clickKeyId:"",
            treeTitle:"",
            module:"",
            editstate:2,
            treename:"",
            treeLevel:-1
        }
    },
    watch: {
        showTreePop(n) {
            if (n == false) {
                this.parentTreeList = []; // 上级树集合
                this.childsTreeList = []; // 子节点
                this.clickParentTree = []; // 点击过的父节点
                this.otherTreeList = []; // 其他树节点
                this.searchTreeVal = "";
            }
        },
    },
    methods:{
        // 打开树
        showEmpTreePop(val, code) {
            this.toWitchCode = code;
            this.treeTitle = val;
            this.showTreePop = true;
            this.clickKeyId = "";
            this.getSelTreeLevelEvent();
            
        },
        getSelTreeLevelEvent() {
            getSelTreeLevel({
                moduleno: this.module,
                treename: this.treename, // 树名
                username: this.userInfo.username
            }).then(res =>{
                console.log(res);
                this.treeLevel = res.data[0].levelid;
                this.getCommTree();
            })
        },
        // 获取通用树
        getCommTree() {
            getCommTreeData({
                moduleno: this.module,
                treename: this.treename, // 树名
                username: this.userInfo.username,
                fieldname: "",
                editstate: this.editstate,
                formstr: "",
            }).then((res) => {
                // 如果是多选树，则返回记录添加是否多选，是否选中属性
                if (this.multiseltree) {
                    res.data.forEach((element) => {
                        element.multiple = this.multiseltree;
                        element.checked = false;
                    });
                }
                this.allTreeList = res.data;
                
                // 树结构中最小层级(最上层)
                let mini = Math.min.apply(
                    Math,
                    res.data.map((item) => {
                        return item.levelid;
                    })
                );
                if (this.whichTree == "station" || this.whichTree == "employee") {
                    // console.log(this.allTreeList,'this.allTreeList')
                    // 岗位和员工树
                    // 初始时的父节点
                    this.parentTreeList = res.data.filter((item) => {
                        return item.levelid == mini;
                    });
                    // 判断父节点有没有下级
                    if (this.parentTreeList.length > 0) {
                        this.parentTreeList.forEach((el) => {
                            el.hasLower = false;
                            for (let i = 0; i < this.allTreeList.length; i++) {
                                const it = this.allTreeList[i];
                                if (el.keyid == it.parentid) {
                                    el.hasLower = true;
                                    break;
                                }
                            }
                        });
                    }
                    // 初始时的子节点
                    this.allTreeList.forEach((el) => {
                        if (this.parentTreeList.length == 1) {
                            // 最大父节点只有一个的时候
                            let nodecan = false;
                            if (this.whichTree == "station") {
                                nodecan = el.issta;
                            } else if (this.whichTree == "employee") {
                                nodecan = el.isdept == 0 && el.levelid == 16;
                            }
                            if (nodecan && el.parentid == this.parentTreeList[0].keyid) {
                                this.childsTreeList.push(el);
                            }
                        } else {
                            // 最大父节点有多个的时候
                            this.childsTreeList = [];
                        }
                    });
                } else {
                    // 其他树(暂时部门树)
                    // 初始时的节点
                    this.otherTreeList = res.data.filter((item) => {
                        return item.levelid == mini;
                    });
                    // 判断初始节点有没有下级
                    if (this.otherTreeList.length > 0) {
                        this.otherTreeList.forEach((el) => {
                            el.hasLower = false;
                            for (let i = 0; i < this.allTreeList.length; i++) {
                                const it = this.allTreeList[i];
                                if (el.keyid == it.parentid) {
                                    el.hasLower = true;
                                    break;
                                }
                            }
                        });
                    }
                }
            });
        },
        // 搜索子节点
        onSearchTree(val) {
            // 清空父节点、子节点和已点击过的父节点
            this.parentTreeList = [];
            this.childsTreeList = [];
            this.clickParentTree = [];
            this.otherTreeList = [];
            this.allTreeList.forEach((el) => {
                let nodecan = false;
                if (this.whichTree == "station") {
                    nodecan = el.issta;
                } else if (this.whichTree == "employee") {
                    nodecan = el.isdept == 0 && el.levelid == 16;
                }
                // 岗位和人员
                if (this.whichTree == "station" || this.whichTree == "employee") {
                    if (nodecan && el.keyname.indexOf(val) !== -1) {
                        this.childsTreeList.push(el);
                    }
                } // 其他（部门）
                else {
                    if (el.keyname.indexOf(val) !== -1) {
                        // 判断节点有没有下级
                        let lowerAry = this.allTreeList.filter((it) => {
                            return it.parentid == el.keyid;
                        });
                        if (lowerAry.length > 0) {
                            el.hasLower = true;
                        } else {
                            el.hasLower = false;
                        }
                        this.otherTreeList.push(el);
                    }
                }
            });
        },
        // 回到某个树节点
        backTo(val) {
            if (val === true) {
                // 点击的开始,回到最初始状态
                this.parentTreeList = [];
                this.childsTreeList = [];
                this.clickParentTree = [];
                this.otherTreeList = [];
                this.searchTreeVal = "";
                this.allTreeList = [];
                this.getCommTree(this.multiseltree);
            } else {
                // 否则先在clickParentTree去掉该父节点及节点后所有节点
                let ary = []; // 记录该节点及后的所有节点
                for (let i = 0; i < this.clickParentTree.length; i++) {
                    const ele = this.clickParentTree[i];
                    if (val.keyid == ele.keyid) {
                        // 如果是数组最后一个则不可以点击，数据不变
                        if (i == this.clickParentTree.length - 1) {
                            return false;
                        } else {
                            ary = this.clickParentTree.filter((item, index) => {
                                return index < i;
                            });
                        }
                    }
                }
                this.clickParentTree = ary;
                // 去掉之后再区分（岗位和员工）和其他树
                if (this.whichTree == "station" || this.whichTree == "employee") {
                    // 岗位和员工树
                    // 再获取该父节点下的子节点
                    this.checkParent(val);
                } else {
                    // 其他树
                    this.hasLower(val);
                }
            }
        },
        // 其他树点击下级
        hasLower(val) {
            if(val.keyid === this.clickKeyId){
                return;
            }
            this.clickKeyId = val.keyid;
            
            // 储存点击过的有下级的父节点
            if (val.hasLower) {
                this.clickParentTree.push(val);
            }
            // 获取此节点下所有子节点
            let array = this.allTreeList.filter((item) => {
                return item.parentid == val.keyid;
            });
            if (array.length > 0) {
                this.otherTreeList = [];
                array.forEach((item) => {
                    // 判断节点有没有下级
                    let lowerAry = this.allTreeList.filter((it) => {
                        return it.parentid == item.keyid;
                    });
                    if (lowerAry.length > 0) {
                        item.hasLower = true;
                    } else {
                        item.hasLower = false;
                    }
                    // 父节点
                    this.otherTreeList.push(item);
                });
            }
        },
        // 点击树的父节点(针对岗位和员工树)
        checkParent(val) {
            // 储存点击过的有下级的父节点
            if (val.hasLower) {
                this.clickParentTree.push(val);
            }
            // 获取此父节点下所有节点
            let array = this.allTreeList.filter((item) => {
                return item.parentid == val.keyid;
            });
            // 遍历所有节点并分出父节点和子节点
            if (array.length > 0) {
                this.parentTreeList = [];
                this.childsTreeList = [];
                array.forEach((item) => {
                    let nodecan = false;
                    if (this.whichTree == "station") {
                        nodecan = item.issta;
                    } else if (this.whichTree == "employee") {
                        nodecan = item.isdept == 0 && item.levelid == 16;
                    }
                    if (nodecan) {
                        // 子节点
                        this.childsTreeList.push(item);
                    } else {
                        // 判断父节点有没有下级
                        let lowerAry = this.allTreeList.filter((it) => {
                            return it.parentid == item.keyid;
                        });
                        if (lowerAry.length > 0) {
                            item.hasLower = true;
                        } else {
                            item.hasLower = false;
                        }
                        // 父节点
                        this.parentTreeList.push(item);
                    }
                });
            }
        },
        // 选择父节点复选框
        tickParent(item) {
            // 判断父节点有没有下级
            let lowerAry = this.allTreeList.filter((it) => {
                return it.parentid == item.keyid;
            });
            if (lowerAry.length > 0) {
                if (item.checked == true) {
                    lowerAry.forEach((ele) => {
                        this.allTreeList.map((it) => {
                            if (it.keyid == ele.keyid) {
                                it.checked = true;
                            }
                        });
                        this.tickParent(ele);
                    });
                } else {
                    lowerAry.forEach((ele) => {
                        this.allTreeList.map((it) => {
                            if (it.keyid == ele.keyid) {
                                it.checked = false;
                            }
                        });
                        this.tickParent(ele);
                    });
                }
            }
        },
         // 多选树清除复选框选中
        cleanChecked() {
            this.allTreeList.forEach((item) => {
                item.checked = false;
            });
        },
        // 多选树确认复选框选中
        comfirmChecked() {
            let array = this.allTreeList.filter((ele) => {
                return ele.checked == true;
            });
            let trueList = [];
            if (array.length > 0) {
                if (this.whichTree == "station") {
                    for (let i = 0; i < array.length; i++) {
                        const it = array[i];
                        if (it.issta == true) {
                            trueList.push(it);
                        }
                    }
                } else if (this.whichTree == "employee") {
                    for (let l = 0; l < array.length; l++) {
                        const item = array[l];
                        if (item.isdept == 0 && item.levelid == 16) {
                            trueList.push(item);
                        }
                    }
                } else {
                    // 其他树
                    trueList = array;
                }
            }
            this.checkChild(trueList);
        },
        // 点击树的子节点(暂时单选树)(针对岗位和员工树)
        checkChild(it) {
            if(it.levelid == this.treeLevel){
                let checked = [];
                this.hasEXfieldlist = [];
                if (this.multiseltree) {
                    checked = it;
                    //多选树
                    const namelist = checked.map((it) =>
                        it.keyname.indexOf(" ") != -1 ? it.keyname.split(" ")[0] : it.keyname
                    );
                    const names = namelist.join(",");
                    // this.setValue(this.Selectformdata.fieldname, String(names))
                    // // 下拉字段带出过程
                    // if (this.Selectformdata.isexitvalue) {
                    //   this.valuechage(names, this.Selectformdata) //判断数据带出
                    // }
                } else {
                    checked = [it];
                    this.$emit("treeSelectValEvent",it)
                    // console.log(it, "itititit");
                }
                // 最终关闭弹窗
                this.showTreePop = false;
            }else{
                this.hasLower(it);
            }
        },
    }
}
</script>
<style lang="less" scoped>
.tree_popup {
    z-index: 6666 !important;
    .tree_title {
        height: 90px;
        text-align: center;
        span {
            font-size: 36px;
            font-weight: bold;
        }
    }
    .tree_search {
        height: 100px;
    }
    .tree_head {
        height: 90px;
        white-space: nowrap;
        overflow-x: auto;
        display: flex;
        align-items: center;
        span {
            font-size: 32px;
        }
        .theLast {
            color: #969799;
            margin-right: 10px;
        }
        .noLast {
            color: #1491f7;
        }
        .head_start {
            color: #1491f7;
            padding: 0 10px 0 20px;
        }
        .division {
            color: #969799;
            padding: 0 16px;
        }
    }
    .van-divider::before {
        border-width: 20px 0 0;
    }
    .tree_body {
        // height: calc(100% - 5.7rem);  // 多选树时的高度
        height: calc(100% - 314px); // 单选树时的高度
        overflow: auto;
        .parents_cell {
            .van-checkbox {
                padding-right: 10px;
            }
            .van-cell__title {
                flex: 1 1 83%;
            }
            .van-cell__value {
                flex: 1 1 17%;
                div {
                    display: flex;
                    align-items: center;
                    justify-content: end;
                    border-left: 1px solid #969799;
                }
                .haveClo {
                    color: #1491f7;
                }
                .noClo {
                    color: #969799;
                }
                .van-icon {
                    padding: 0 10px;
                }
            }
        }
        .childs_cell,
        .other_cell {
            .van-cell__title {
                display: flex;
                align-items: center;
                .van-button {
                    border-radius: 12px;
                    font-size: 28px;
                }
                .check_name {
                    padding-left: 10px;
                }
            }
        }
        // .other_cell {
        //   .van-cell__title {
        //     display: flex;
        //     align-items: center;
        //     .van-button {
        //       border-radius: 12px;
        //       font-size: 28px;
        //     }
        //   }
        // }
    }
    .tree_foot {
        position: absolute;
        bottom: 0;
        width: 100%;
        background: #fff;
        display: flex;
        justify-content: space-evenly;
        .van-button {
            width: 30vw;
            margin-bottom: 10px;
        }
    }

    /deep/.van-popup__close-icon--top-left {
        top: 25px !important;
        left: 10px !important;
    }
}
</style>